.dd-wrapper {
  margin: 0.2rem;
}
@media only screen and (max-width: 390px) {
  .dd-wrapperTheme {
    /* border-style: dotted; */
    /* width: 25%; */
    /* transform: translateX(-100%); */
    float: left;
    z-index: 3;
    color: #fcee09;
    margin-right: 0.25rem;
    height: 35%;
    clip-path: polygon(92% 0, 100% 25%, 100% 100%, 8% 100%, 0% 75%, 0 0);
    position: absolute;
    height: auto;
  }
}