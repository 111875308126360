.buttonDiv {
  border-radius: 8px;
  border: 3px solid #fcee09;
  cursor: pointer;
  width: 100px;
}

.buttonDiv-dark {
  border-radius: 8px;
  border: 3px solid #15b5e2;
  cursor: pointer;
  width: 100px;
}

.buttonDiv:hover {
  animation: border-flicker 2s linear forwards;
}

.buttonDiv-dark:hover {
  animation: border-flicker 2s linear forwards;
}

.repo {
  color: #fff;
  text-decoration: none;
}

.letsGo {
  color: #fcee09;
  background-color: Transparent;
  border: none;
  padding: 0.5rem;
  width: 100px;
}

.letsGo-dark {
  color: #15b5e2;
  background-color: Transparent;
  border: none;
  padding: 0.5rem;
  width: 100px;
}

.letsGo:hover {
  animation: text-flicker 2s linear forwards;
}

.letsGo-dark:hover {
  animation: text-flicker 2s linear forwards;
}

.sponsor {
  position: absolute;
  left: 64px;
  bottom: 75px;
}

.Judge0 {
  width: 150px;
  margin-top: 0.5rem;
  position: absolute;
  left: 64px;
  bottom: 20px;
}

.logoutBtn {
  font-size: .85rem;
  color: #fcee09;
  background-color: Transparent;
  border: none;
  padding: 0.5rem;
  width: 50px;
  z-index: 3;
  position: fixed;
  top: 9px;
  right: 1%;
}

.logoutBtn-dark {
  font-size: .85rem;
  color: #15b5e2;
  background-color: Transparent;
  border: none;
  padding: 0.5rem;
  width: 50px;
  z-index: 3;
  position: fixed;
  top: 9px;
  right: 1%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sponsor {
    position: absolute;
    left: 30px;
    bottom: 75px;
    font-size: small;
  }

  .Judge0 {
    width: 100px;
    margin-top: 0.5rem;
    position: absolute;
    left: 30px;
    bottom: 20px;
  }
}

@keyframes text-flicker {
  2% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  3% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  6% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  9% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  11% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  14% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  18% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  32% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  33% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  37% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  39% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  43% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  46% {
    color: rgba(120, 0, 50, 0.5);
    text-shadow: none;
  }
  47% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  100% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
}

@keyframes border-flicker {
  2% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
      0 0 12px -1px rgb(0, 180, 230) inset;
  }
  3% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  5% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
      0 0 12px -1px rgb(0, 180, 230) inset;
  }
  6% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  7% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
      0 0 12px -1px rgb(0, 180, 230) inset;
  }
  9% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  13% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
      0 0 12px -1px rgb(0, 180, 230) inset;
  }
  16% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  18% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
      0 0 12px -1px rgb(0, 180, 230) inset;
  }
  22% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  34% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
      0 0 12px -1px rgb(0, 180, 230) inset;
  }
  36% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  54% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
      0 0 12px -1px rgb(0, 180, 230) inset;
  }
  100% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
      0 0 12px -1px rgb(0, 180, 230) inset;
  }
}

@media only screen and (max-width: 390px) {

}