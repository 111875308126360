.interviews {
    position: fixed;
    left: 7%;
    padding-bottom: 7%;
}

.addBtn {
    -webkit-filter: drop-shadow(0 0 0.5px white) drop-shadow(0 0 0.1px white)
      drop-shadow(0 0 0.2px white) drop-shadow(0 0 0.3px white);
  
    filter: drop-shadow(0 0 0.1px #fcee09) drop-shadow(0 0 0.1px #fcee09)
      drop-shadow(0 0 1px #fff) drop-shadow(0 0 1px #fff);
    position: fixed;
    right: 7%;
    top: 7%;
  }

  .addBtn:hover {
    -webkit-filter: drop-shadow(0 0 0.5px #fcee09) drop-shadow(0 0 0.1px #fcee09)
      drop-shadow(0 0 0.2px #fcee09) drop-shadow(0 0 0.3px #fcee09);
  
    filter: drop-shadow(0 0 0.25px #fcee09) drop-shadow(0 0 0.25px #fcee09)
      drop-shadow(0 0 2.5px #fcee09) drop-shadow(0 0 2.5px #fcee09);
  }

  .listBorder{
    position: relative;
    left: 7%;
    /* margin-top: 3%; */
    padding-top: 1.7%;
  }

  .listTop {
    margin-top: 3%;
  }

  .listBorder {

  }

  .list {
    margin-top: 1%;
  }
  
  .link {
    color: #fff;
    text-decoration: none;
  }

  @media only screen and (max-width: 390px) {
    .listTop {
      margin-top: 12%;
    }

    .addBtn {
      right: 14%
    }
  }