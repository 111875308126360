.btn {
  border: 0;
  outline: none;
  font-family: Tomorrow, sans-serif;
  text-transform: uppercase;
  clip-path: polygon(92% 0, 100% 25%, 100% 100%, 8% 100%, 0% 75%, 0 0);
}

.videoContainer {
  
}

.buttonsRow {
  z-index: 3;
}

.callButton {
  background-color: #fcee09;
  margin: 0.25rem;
  color: #000;
  transition: transform 0.6s 0.25s ease-out;
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-top: 0.5rem;
}

.callButton:hover {
  opacity: 0.7;
}

.callButton-dark {
  background-color: #fcee09;
  margin: 0.25rem;
  color: #000;
  transition: transform 0.6s 0.25s ease-out;
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-top: 0.5rem;
  opacity: 0.5;
}

.btn__content {
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background-color: #fcee09;
  font-size: 0.9em;
  clip-path: polygon(92% 0, 100% 25%, 100% 100%, 8% 100%, 0% 75%, 0 0);
}

Video {
  height: calc(17.5vh);
  left: -3%
}

.videoDiv {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  position: fixed;
  /* right: 15.5%; */
}

.videoAdjust{
  left: -220%;
  top: 35%
}

@media only screen and (max-width: 1440px){
  Video {
    height: calc(10vh);
  }
  .videoDiv {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .buttonsRow {
    position: absolute;
    right: 0px;
    margin-right: 0.5%;
    z-index: 4;
  }
}

.mic {
  width: 1.1rem;
}

.toggleVid-on {
  -webkit-filter: drop-shadow(0 0 0.5px #4cdd71)
  drop-shadow(0 0 0.1px #4cdd71) drop-shadow(0 0 0.2px #4cdd71)
  drop-shadow(0 0 0.3px #4cdd71);

filter: drop-shadow(0 0 .1px #4cdd71) drop-shadow(0 0 .1px #4cdd71)
  drop-shadow(0 0 1px #4cdd71) drop-shadow(0 0 1px #4cdd71);
}

.toggleVid-off {
  -webkit-filter: drop-shadow(0 0 0.5px #a51c30)
  drop-shadow(0 0 0.1px #a51c30) drop-shadow(0 0 0.2px #a51c30)
  drop-shadow(0 0 0.3px #a51c30);

filter: drop-shadow(0 0 .1px #a51c30) drop-shadow(0 0 .1px #a51c30)
  drop-shadow(0 0 1px #a51c30) drop-shadow(0 0 1px #a51c30);
}

.toggleAud-on {
  -webkit-filter: drop-shadow(0 0 0.5px #4cdd71)
  drop-shadow(0 0 0.1px #4cdd71) drop-shadow(0 0 0.2px #4cdd71)
  drop-shadow(0 0 0.3px #4cdd71);

  filter: drop-shadow(0 0 .1px #4cdd71) drop-shadow(0 0 .1px #4cdd71)
    drop-shadow(0 0 1px #4cdd71) drop-shadow(0 0 1px #4cdd71);
}

.toggleAud-off {
  -webkit-filter: drop-shadow(0 0 0.5px #a51c30)
  drop-shadow(0 0 0.1px #a51c30) drop-shadow(0 0 0.2px #a51c30)
  drop-shadow(0 0 0.3px #a51c30);

  filter: drop-shadow(0 0 .1px #a51c30) drop-shadow(0 0 .1px #a51c30)
    drop-shadow(0 0 1px #a51c30) drop-shadow(0 0 1px #a51c30);
}

.callBtn-pulse {
  -webkit-filter: drop-shadow(0 0 0.5px #4cdd71)
  drop-shadow(0 0 0.1px #4cdd71) drop-shadow(0 0 0.2px #4cdd71)
  drop-shadow(0 0 0.3px #4cdd71);

  filter: drop-shadow(0 0 .1px #4cdd71) drop-shadow(0 0 .1px #4cdd71)
    drop-shadow(0 0 1px #4cdd71) drop-shadow(0 0 1px #4cdd71);
	
}

@media only screen and (max-width: 390px){
  .buttonsRow {
    position: absolute;
    right: 0px;
    margin-right: 0.5%;
    z-index: 4;
  }
}