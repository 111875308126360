.outer-div {
  display: table;
  box-sizing: border-box;
  margin: 2px;
  /* padding: 10px; */
}

.t {
  display: table-cell;
  width: 100%;
}

.t > input {
  width: 100%;
  box-sizing: border-box;
}

@font-face {
  font-family: Modenine-2OPd;
  src: local("Modenine-2OPd"),
    url(../fonts/Modenine-2OPd.ttf) format("truetype");
}

.time {
  /* padding: 1em; */
  color: #66ff66;
  font-family: Modenine-2OPd;
  text-shadow: 0.06rem 0 0.06rem #ea36af, -0.125rem 0 0.06rem #75fa69;
  letter-spacing: 0.125em;
  animation-duration: 0.01s;
  animation-name: textflicker;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  font-size: 8px;
}

.time-plain {
  /* padding: 1em; */
  color: #fff;
  font-family: Modenine-2OPd;
  letter-spacing: 0.125em;
  font-size: 8px;
}

.timer-btn {
  background-color: #fcee09;
  color: black;
  clip-path: polygon(92% 0, 100% 25%, 100% 100%, 8% 100%, 0% 75%, 0 0);
  border: 0;
  outline: none;
  float: right;
}

.timer-btn:hover {
  opacity: 0.7;
}

.timer-btn:target {
  opacity: 0.3;
}

.user-box {
  position: relative;
}

.user-box input {
  width: 100%;
  padding-top: 0.5rem;
  font-size: 16px;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  font-family: Modenine-2OPd;
}
.user-box label {
  position: absolute;
  top:0;
  left: 0;
  padding: 1.15em 0;
  font-size: 16px;
  pointer-events: none;
  transition: .5s;
}

.user-box input:focus ~ label,
.user-box input:valid ~ label {
  top: -20px;
  left: 0;
  font-size: 12px;
  font-family: Modenine-2OPd;
}

.goBtn {
  -webkit-filter: drop-shadow(0 0 1px white)
    drop-shadow(0 0 0.1px white) drop-shadow(0 0 0.2px white)
    drop-shadow(0 0 0.3px white);

  filter: drop-shadow(0 0 .1px #fcee09) drop-shadow(0 0 .1px #fcee09)
    drop-shadow(0 0 1px #fff) drop-shadow(0 0 1px #fff);
}

.goBtn:hover {
  -webkit-filter: drop-shadow(0 0 1px #fcee09)
    drop-shadow(0 0 0.1px #fcee09) drop-shadow(0 0 0.2px #fcee09)
    drop-shadow(0 0 0.3px #fcee09);

  filter: drop-shadow(0 0 .25px #fcee09) drop-shadow(0 0 .25px #fcee09)
    drop-shadow(0 0 2.5px #fcee09) drop-shadow(0 0 2.5px #fcee09);
}