.card {
    padding: 0 0 3% 3%;
    margin: 0 0.14rem 2% 2%;
    box-shadow: #fcee09 -3px 0px 0px 0px;
    z-index: 3;
    overflow-wrap: break-word;
}

.card-dark {
    padding: 0 0 3% 3%;
    margin: 0 0.14rem 2% 2%;
    box-shadow: #15b5e2 -3px 0px 0px 0px;
    z-index: 3;
    overflow-wrap: break-word;
}

.line {
    white-space: pre-wrap;
}

.cardCreated {
    padding: 1% 0 1% 2%;
    margin: 3% 0 2% 2%;
    box-shadow: #fcee09 -3px 0px 0px 0px;
    z-index: 3;
}

.cardCreated-dark {
    padding: 1% 0 1% 2%;
    margin: 3% 0 2% 2%;
    box-shadow: #15b5e2 -3px 0px 0px 0px;
    z-index: 3;
}

.closeBtn {
    /* position: absolute; */
    /* -webkit-filter: drop-shadow(0 0 0.5px #EB212E) drop-shadow(0 0 0.1px #EB212E)
      drop-shadow(0 0 0.2px #EB212E) drop-shadow(0 0 0.3px #EB212E); */
    
    filter: drop-shadow(0 0 0.1px #EB212E) drop-shadow(0 0 0.1px #EB212E)
      drop-shadow(0 0 1px #EB212E) drop-shadow(0 0 1px #EB212E);
      float: right;
}

.closeBtn:hover {
    -webkit-filter: drop-shadow(0 0 0.5px #EB212E) drop-shadow(0 0 0.1px #EB212E)
      drop-shadow(0 0 0.2px #EB212E) drop-shadow(0 0 0.3px #EB212E);
  
    filter: drop-shadow(0 0 0.25px #EB212E) drop-shadow(0 0 0.25px #EB212E)
      drop-shadow(0 0 2.5px #EB212E) drop-shadow(0 0 2.5px #EB212E);
  }

.copyBtn {
    /* position: absolute; */
    left: 14%;
    -webkit-filter: drop-shadow(0 0 0.5px white) drop-shadow(0 0 0.1px white)
    drop-shadow(0 0 0.2px white) drop-shadow(0 0 0.3px white);

  filter: drop-shadow(0 0 0.1px #fcee09) drop-shadow(0 0 0.1px #fcee09)
    drop-shadow(0 0 1px #fff) drop-shadow(0 0 1px #fff);
    float: right;
  }

  .cardBtns {
    display: grid;
    grid-template-columns: 10% 10%;
  }

  .copyBtn:hover {
    -webkit-filter: drop-shadow(0 0 0.5px #fcee09) drop-shadow(0 0 0.1px #fcee09)
      drop-shadow(0 0 0.2px #fcee09) drop-shadow(0 0 0.3px #fcee09);
  
    filter: drop-shadow(0 0 0.25px #fcee09) drop-shadow(0 0 0.25px #fcee09)
      drop-shadow(0 0 2.5px #fcee09) drop-shadow(0 0 2.5px #fcee09);
  }

  .cardInfo {
    /* padding-top: 5%; */
  }

  @media only screen and (max-width: 412px) {
    .copyBtn {
        left: 27%;
    }
    .cardBtns {
        display: grid;
        grid-template-columns: 25% 25%;
      }
  }

  @media only screen and (max-width: 393px) {
    .copyBtn {
        left: 27%;
    }
    .cardBtns {
        display: grid;
        grid-template-columns: 25% 25%;
      }
}

@media only screen and (max-width: 480px) {
    .copyBtn {
        left: 27%;
    }
    .cardBtns {
        display: grid;
        grid-template-columns: 25% 25%;
      }
}

.cardTitle {
  display: grid;
  grid-template-columns: 8% 90%;
}