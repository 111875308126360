.mainLogo {
  -webkit-filter: drop-shadow(0 0 1px white)
    drop-shadow(0 0 0.1px white) drop-shadow(0 0 0.2px white)
    drop-shadow(0 0 0.3px white);

  filter: drop-shadow(0 0 .1px #fcee09) drop-shadow(0 0 .1px #fcee09)
    drop-shadow(0 0 1px #fff) drop-shadow(0 0 1px #fff);

  width: 1.1rem;
  margin-right: 0.5rem;
}

.mainLogo-dark {
  -webkit-filter: drop-shadow(0 0 5px white)
    drop-shadow(0 0 1px white) drop-shadow(0 0 2px white)
    drop-shadow(0 0 3px white);

  filter: drop-shadow(0 0 .1px #15b5e2) drop-shadow(0 0 .1px #15b5e2)
    drop-shadow(0 0 1px #fff) drop-shadow(0 0 1px #fff);

  width: 1.1rem;
  margin-right: 0.5rem;
}

.extras {
  margin-right: 0.3rem;
  font-size: x-small;
  position: relative;
  right: 3.5rem;
}

.PH {
  position: relative;
  right: 80px;
}

@media only screen and (max-width: 1440px) {
  .PH {
    height: 5rem;
  }
}

@media only screen and (max-width: 480px) {
  .mainLogo {
    -webkit-filter: drop-shadow(0 0 1px white)
      drop-shadow(0 0 0.1px white) drop-shadow(0 0 0.2px white)
      drop-shadow(0 0 0.3px white);
  
    filter: drop-shadow(0 0 .1px #fcee09) drop-shadow(0 0 .1px #fcee09)
      drop-shadow(0 0 1px #fff) drop-shadow(0 0 1px #fff);
  
    width: 1.1rem;
    margin-right: 0.5rem;
    position: absolute;
    left: 3.5%;
  }

  .mainLogo-dark {
    -webkit-filter: drop-shadow(0 0 5px white)
      drop-shadow(0 0 1px white) drop-shadow(0 0 2px white)
      drop-shadow(0 0 3px white);
  
    filter: drop-shadow(0 0 .1px #15b5e2) drop-shadow(0 0 .1px #15b5e2)
      drop-shadow(0 0 1px #fff) drop-shadow(0 0 1px #fff);
  
    width: 1.1rem;
    margin-right: 0.5rem;
    position: absolute;
    left: 3.5%;
  }

  .extras {
    margin-right: 0.3rem;
    font-size: x-small;
    position: relative;
    right: -18%;
    top: 1.35rem;
  }
}

@media only screen and (max-width: 412px) {
  .mainLogo {
    -webkit-filter: drop-shadow(0 0 1px white)
      drop-shadow(0 0 0.1px white) drop-shadow(0 0 0.2px white)
      drop-shadow(0 0 0.3px white);
  
    filter: drop-shadow(0 0 .1px #fcee09) drop-shadow(0 0 .1px #fcee09)
      drop-shadow(0 0 1px #fff) drop-shadow(0 0 1px #fff);
  
    width: 1.1rem;
    margin-right: 0.5rem;
    position: absolute;
    left: 3.5%;
  }

  .mainLogo-dark {
    -webkit-filter: drop-shadow(0 0 5px white)
      drop-shadow(0 0 1px white) drop-shadow(0 0 2px white)
      drop-shadow(0 0 3px white);
  
    filter: drop-shadow(0 0 .1px #15b5e2) drop-shadow(0 0 .1px #15b5e2)
      drop-shadow(0 0 1px #fff) drop-shadow(0 0 1px #fff);
  
    width: 1.1rem;
    margin-right: 0.5rem;
    position: absolute;
    left: 3.5%;
  }

  .extras {
    margin-right: 0.3rem;
    font-size: x-small;
    position: relative;
    right: -22%;
    top: 1.35rem;
  }
}

@media only screen and (max-width: 393px) {
  .mainLogo {
    -webkit-filter: drop-shadow(0 0 1px white)
      drop-shadow(0 0 0.1px white) drop-shadow(0 0 0.2px white)
      drop-shadow(0 0 0.3px white);
  
    filter: drop-shadow(0 0 .1px #fcee09) drop-shadow(0 0 .1px #fcee09)
      drop-shadow(0 0 1px #fff) drop-shadow(0 0 1px #fff);
  
    width: 1.1rem;
    margin-right: 0.5rem;
    position: absolute;
    left: 3.5%;
  }

  .mainLogo-dark {
    -webkit-filter: drop-shadow(0 0 5px white)
      drop-shadow(0 0 1px white) drop-shadow(0 0 2px white)
      drop-shadow(0 0 3px white);
  
    filter: drop-shadow(0 0 .1px #15b5e2) drop-shadow(0 0 .1px #15b5e2)
      drop-shadow(0 0 1px #fff) drop-shadow(0 0 1px #fff);
  
    width: 1.1rem;
    margin-right: 0.5rem;
    position: absolute;
    left: 3.5%;
  }

  .extras {
    margin-right: 0.3rem;
    font-size: x-small;
    position: relative;
    right: -22%;
    top: 1.35rem;
  }
}