/* * {
  outline: solid 1px red;
} */

.App {
  font-family: "Nunito", sans-serif;
  background-color: #282a36;
  color: #fff;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
}

.App-dark {
  font-family: "Nunito", sans-serif;
  background-color: #000;
  color: #fff;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
}

.App-header {
  background-color: #282a36;
  height: 50px;
  display: flex;
  align-items: center;
  color: white;
  padding: 0px 64px;
}

.App-header .extras {
  margin-left: auto;
}
.App-header-dark {
  background-color: #000;
  height: 50px;
  display: flex;
  align-items: center;
  color: white;
  padding: 0px 64px;
}
.App-header-dark .extras {
  margin-left: auto;
}

.App-title {
  color: #fcee09;
  font-size: 18px;
  font-weight: 700;
  line-height: 36px;
  text-decoration: none;
}
.App-title:active,
.App-title:hover {
  color: #fff;
}

.App-title-dark {
  color: #15b5e2;
  font-size: 18px;
  font-weight: 700;
  line-height: 36px;
  text-decoration: none;
}
.App-title-dark:active,
.App-title-dark:hover {
  color: #fff;
}

.neonText {
  color: #fff;
  text-shadow: 0 0 1px #fff, 0 0 3px #fcee09;
  /* 0 0 5px #fcee09; */
}

.neonText-dark {
  color: #fff;
  text-shadow: 0 0 1px #fff, 0 0 3px #15b5e2;
  /* 0 0 5px #15b5e2; */
}

.homepage {
  display: flex;
  padding: 0px 64px;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 50px);
}

.codingpage {
  height: calc(100vh - 50px);
}

.CodeMirror-theme .CodeMirror-gutter {
  /* border-bottom: 2.2px solid #282a36; */
  /* padding: 16px 32px; */
  background-color: #282a36;
  word-break: break-word;
}
.CodeMirror-theme-dark .CodeMirror-gutter {
  /* border-bottom: 2.2px solid #282a36; */
  /* padding: 16px 32px; */
  background-color: #000 !important;
  word-break: break-word;
}

.CodeMirror-wrap {
  /* background-color: #000 !important; */
}

.cm-s-dracula.CodeMirror {
  padding: 0px;
  height: calc(76vh - 50px);
  background-color: transparent;
}

p {
  margin: 0;
}

.title {
  font-size: 36px;
  font-weight: 100;
  margin-bottom: 12px;
}

.sub-title {
  font-size: 16px;
  font-weight: 700;
  color: rgb(124, 124, 124);
  margin-bottom: 36px;
  max-width: 500px;
}
.highlight {
  color: #fcee09;
}

.highlight-dark {
  color: #15b5e2;
}

.neon {
  color: #fff;
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fcee09,
    0 0 80px #fcee09, 0 0 90px #fcee09, 0 0 100px #fcee09, 0 0 120px #fcee09;
}

.neon-dark {
  color: #fff;
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #15b5e2,
    0 0 80px #15b5e2, 0 0 90px #15b5e2, 0 0 100px #15b5e2, 0 0 120px #15b5e2;
}

.cursive {
  font-family: "Vibur", cursive;
}

@media only screen and (max-width: 1440px) {
  .App-header {
    height: 50px;
  }

  .App-header-dark {
    height: 50px;
  }
  .App-title {
    font-size: 17px;
  }
  .App-title-dark {
    font-size: 17px;
  }
  .createdOn {
    font-size: 8px;
  }

  .CodeMirror-code {
    font-size: 17px;
  }

  .CodeMirror-line {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 1080) {
  .App-header {
    height: 50px;
  }

  .App-header-dark {
    height: 50px;
  }
  .App-title {
    font-size: 13px;
  }
  .App-title-dark {
    font-size: 13px;
  }
  .createdOn {
    font-size: 8px;
  }

  .CodeMirror-code {
    font-size: 13px;
  }

  .CodeMirror-line {
    margin-left: 1rem;
  }
}

/* Extra small devices (phones, 818px and down) */
@media only screen and (max-width: 818px) {
  .App-title .App-title-dark {
    font-size: 10px;
    line-height: 24px;
  }

  .extras {
    font-size: 10px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .App-title .App-title-dark {
    font-size: 10px;
    line-height: 24px;
  }

  .extras {
    font-size: 10px;
  }

  .title {
    font-size: 24px;
    font-weight: 67;
    margin-bottom: 12px;
  }

  .sub-title {
    font-size: 11px;
    font-weight: 470;
    color: rgb(124, 124, 124);
    margin-bottom: 36px;
    max-width: 500px;
  }
}

/* Extra small devices (phones, 548px and down) */
@media only screen and (max-width: 548px) {
  .App-title .App-title-dark {
    font-size: 8px;
    line-height: 18px;
  }
  .extras {
    font-size: 8px;
  }
}

/* Extra small devices (phones, 463px and down) */
@media only screen and (max-width: 463px) {
  .App-title .App-title-dark {
    font-size: 6.5px;
    line-height: 16px;
  }
  .extras {
    font-size: 6.5px;
  }
}

@media only screen and (max-width: 480px) {
  .App-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 36px;
    text-decoration: none;
    position: absolute;
    left: 10%;
  }
  .App-title:active,
  .App-title:hover {
    color: #fff;
  }
  
  .App-title-dark {
    font-size: 18px;
    font-weight: 700;
    line-height: 36px;
    text-decoration: none;
    position: absolute;
    left: 10%;
  }
  .App-title-dark:active,
  .App-title-dark:hover {
    color: #fff;
  }
}

@media only screen and (max-width: 412px) {
  .App-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 36px;
    text-decoration: none;
    position: absolute;
    left: 10%;
  }
  .App-title:active,
  .App-title:hover {
    color: #fff;
  }
  
  .App-title-dark {
    font-size: 18px;
    font-weight: 700;
    line-height: 36px;
    text-decoration: none;
    position: absolute;
    left: 10%;
  }
  .App-title-dark:active,
  .App-title-dark:hover {
    color: #fff;
  }
}

@media only screen and (max-width: 393px) {
  .App-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 36px;
    text-decoration: none;
    position: absolute;
    left: 10%;
  }
  .App-title:active,
  .App-title:hover {
    color: #fff;
  }
  
  .App-title-dark {
    font-size: 18px;
    font-weight: 700;
    line-height: 36px;
    text-decoration: none;
    position: absolute;
    left: 10%;
  }
  .App-title-dark:active,
  .App-title-dark:hover {
    color: #fff;
  }
}
