body {
  background-color: #10131c;
  font-family: "Helvetica Rounded", "Arial Rounded MT Bold", "Montserrat",
    sans-serif;
  color: #fff;
}

.toggleWrapper {
  position: absolute;
  // margin-left: 3.5rem;
  right: -0.3rem;
  overflow: hidden;
  padding: 0 1.7rem;
  transform: translate3d(-50%, -50%, 0);
  transform: scale(0.65);
  top: 0%;

  input {
    position: absolute;
    left: -99em;
  }
}

@media only screen and (max-width: 1440px){
  .toggleWrapper {
    // top: 15px;
  }
}

.toggle {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 90px;
  height: 50px;
  background-color: #83d8ff;
  border-radius: 90px - 6;
  transition: background-color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

  &:before {
    // content: "Dusk";
    position: absolute;
    left: -50px;
    top: 15px;
    font-size: 18px;
  }

  &:after {
    // content: "Dark";
    position: absolute;
    right: -48px;
    top: 15px;
    font-size: 18px;
    color: #749ed7;
  }
  transform: scale(0.8);
}

.toggle__handler {
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 3px;
  right: -4px;
  width: 50px - 6;
  height: 50px - 6;
  background-color: #ffcf96;
  border-radius: 50px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform: rotate(-45deg);

  .crater {
    position: absolute;
    background-color: #e8cda5;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    border-radius: 100%;
  }

  .crater--1 {
    top: 18px;
    left: 10px;
    width: 4px;
    height: 4px;
  }

  .crater--2 {
    top: 28px;
    left: 22px;
    width: 6px;
    height: 6px;
  }

  .crater--3 {
    top: 10px;
    left: 25px;
    width: 8px;
    height: 8px;
  }
}

.star {
  position: absolute;
  background-color: #ffffff;
  transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  border-radius: 50%;
}

.star--1 {
  top: 10px;
  left: 35px;
  z-index: 0;
  width: 30px;
  height: 3px;
}

.star--2 {
  top: 18px;
  left: 28px;
  z-index: 1;
  width: 30px;
  height: 3px;
}

.star--3 {
  top: 27px;
  left: 40px;
  z-index: 0;
  width: 30px;
  height: 3px;
}

.star--4,
.star--5,
.star--6 {
  opacity: 0;
  transition: all 300ms 0 cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.star--4 {
  top: 16px;
  left: 11px;
  z-index: 0;
  width: 2px;
  height: 2px;
  transform: translate3d(3px, 0, 0);
}

.star--5 {
  top: 32px;
  left: 17px;
  z-index: 0;
  width: 3px;
  height: 3px;
  transform: translate3d(3px, 0, 0);
}

.star--6 {
  top: 36px;
  left: 28px;
  z-index: 0;
  width: 2px;
  height: 2px;
  transform: translate3d(3px, 0, 0);
}

input:checked {
  + .toggle {
    background-color: #749dd6;

    &:before {
      color: #749ed7;
    }

    &:after {
      color: #ffffff;
    }

    .toggle__handler {
      background-color: #ffe5b5;
      transform: translate3d(39.2px, 0, 0) rotate(0);

      .crater {
        opacity: 1;
      }
    }

    .star--1 {
      width: 2px;
      height: 2px;
    }

    .star--2 {
      width: 4px;
      height: 4px;
      transform: translate3d(-5px, 0, 0);
    }

    .star--3 {
      width: 2px;
      height: 2px;
      transform: translate3d(-7px, 0, 0);
    }

    .star--4,
    .star--5,
    .star--6 {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    .star--4 {
      transition: all 300ms 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
    .star--5 {
      transition: all 300ms 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
    .star--6 {
      transition: all 300ms 400ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
  }
}

/* Extra small devices (phones, 900px and down) */
@media only screen and (max-width: 900px) {
  .toggleWrapper {
    position: fixed;
    top: 0%;
    // left: 62%;
    margin-right: -30rem;
  }
}

@media only screen and (max-width: 900px) {
  .toggleWrapper{
    top: 0%;
    left: 72%;
  }
}

@media only screen and (max-width: 720px) {
  .toggleWrapper{
    top: 0%;
    left: 67%;
  }
}

@media only screen and (max-width: 600px) {
  .toggleWrapper{
    top: 0%;
    left: 59%;
  }
}

@media only screen and (max-width: 480px) {
  .toggleWrapper{
    top: 0%;
    left: 53%;
  }
}

@media only screen and (max-width: 390px) {
  .toggleWrapper {
    left: 43%;
    top: 0%;
  }
}