.coding {
  /* grid-template-areas: 
  "header header"
  "code whiteboard"
  "eval video"
  "ad ad"
  ; */
  display: grid;
  /* grid-template-rows: calc(73vw - 50px) calc(27vw); */
}

.topHalf {
  display: grid;
  /* grid-template-columns: 3fr; */
  /* height: 80vh; */
  max-height: 73vh;
}

.logoutBtn {
  font-size: .85rem;
  color: #fcee09;
  background-color: Transparent;
  border: none;
  padding: 0.5rem;
  width: 50px;
  z-index: 3;
  position: fixed;
  top: 11px;
  right: .4%;
}

.logoutBtn:hover {
  animation: text-flicker 2s linear forwards;
}

.logoutBtn-dark {
  font-size: .85rem;
  color: #15b5e2;
  background-color: Transparent;
  border: none;
  padding: 0.5rem;
  width: 50px;
  z-index: 3;
  position: fixed;
  top: 11px;
  right: .4%;
}

.logoutBtn-dark:hover {
  animation: text-flicker 2s linear forwards;
}

/* .logoutBtn:hover {
  -webkit-filter: drop-shadow(0 0 0.3px #fcee09) drop-shadow(0 0 0.1px #fcee09)
    drop-shadow(0 0 0.2px #fcee09) drop-shadow(0 0 0.1px #fcee09);

  filter: drop-shadow(0 0 0.2px #fcee09) drop-shadow(0 0 0.25px #fcee09)
    drop-shadow(0 0 2.5px #fcee09) drop-shadow(0 0 2.5px #fcee09);
} */

.codeMenu {
  /* display: grid;
  grid-template-columns: 8fr 7.5vw 25vw; */
}

.lowerMenu {
  padding: 0px;
  height: calc(24vh);
}

.witeboard {
  height: calc(100vh - 50px);
  width: 100%;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: none;
  /* grid-area: whiteboard; */
}

.lowerHalf {
  display: grid;
  grid-template-columns: 48fr 1.5fr;
  height: calc(27vh - 50px);
}

.lowerHalf > .videoSec > .videoContainer {
  display: grid !important;
  grid-template-columns: 1fr 10fr;
  /* padding: 0.3rem; */
}

.lowerHalf-extend > .videoSec > .videoContainer {
  display: grid !important;
  grid-template-columns: 1fr;
  /* padding: 0.3rem; */
}

.lowerHalf-extend > .videoSec > .videoContainer > .videoDiv {
  position: fixed;
  z-index: 2;
}

.sponsor2 {
  display: flex;
  justify-content: center;
  height: calc(3vh);
  text-decoration: none;
  color: white;
}

.coding-page {
  /* background-color: #282a36; */
  /* grid-area: code; */
  height: 100%;
  display: grid;
  grid-template-columns: minmax(50%, 8fr) minmax(auto, 7.5vw) minmax(auto, 25vw);
}

.coding-page-whiteboard {
  /* background-color: #000; */
  /* grid-area: code; */
  height: 100%;
  display: grid;
  grid-template-columns: minmax(50%, 8fr) minmax(auto, 7.5vw) minmax(auto, 25vw);
}

/* :root {
  --color1: goldenrod !important;
  --color2: orangered !important;
  --color3: #15b5e2 !important;
  --color4: purple !important;
} */

.styled-background {
  color: #fff !important;
  /* background-color: #192824; */
  opacity: 0.4;
  text-shadow: 
    0 0 1px goldenrod,
    0 0 2px orangered,
    0 0 4px #15b5e2,
    0 0 8px purple;
  will-change: filter, color;
  filter: saturate(40%);
  animation: flicker steps(100) var(--interval) 1s infinite;
}

.tooltip{
  position: fixed;
  font-size: .40rem;
  z-index: 3;
  /* color: #fff !important; */
  /* background-color: #192824; */
  /* opacity: 0.4; */
  /* text-shadow: 
    0 0 1px goldenrod,
    0 0 2px orangered,
    0 0 4px #15b5e2,
    0 0 8px purple;
  will-change: filter, color;
  filter: saturate(40%);
  animation: flicker steps(100) var(--interval) 1s infinite; */

}

.tooltip-text{
  display: grid;
  grid-template-columns: auto auto;
  
}

.tooltip-shift-tab {
  /* display: grid;
  grid-template-columns: 1.2fr 1fr;
  padding: 0 0.1% 0 0; */
}

.tooltip-shift-tab:hover {
  color: #fff !important;
  /* background-color: #192824; */
  opacity: 0.4;
  text-shadow: 
    0 0 1px goldenrod,
    0 0 2px orangered,
    0 0 4px #15b5e2,
    0 0 8px purple;
  will-change: filter, color;
  filter: saturate(40%);
  animation: flicker steps(100) var(--interval) 1s infinite;
}

.tooltip-escape {
  /* display: grid;
  grid-template-columns: 1.2fr 1fr;
  padding: 0 0.1% 0 0; */
}

.tooltip-escape:hover {
  color: #fff !important;
  /* background-color: #192824; */
  opacity: 0.4;
  text-shadow: 
    0 0 1px goldenrod,
    0 0 2px orangered,
    0 0 4px #15b5e2,
    0 0 8px purple;
  will-change: filter, color;
  filter: saturate(40%);
  animation: flicker steps(100) var(--interval) 1s infinite;
}

@keyframes flicker {
  50% {
    color: white;
    filter: saturate(200%) hue-rotate(20deg);
  }
}

@media only screen and (max-width: 1440px) {
  .lowerHalf {
    display: grid;
    grid-template-columns: minmax(auto, 3fr) minmax(auto, 1fr);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 800px) {
  .sponsor2 {
    font-size: small;
  }

  .coding-page {
    height: 100%;
    display: grid;
    grid-template-columns: minmax(auto, 8fr) minmax(auto, 7.5vw) minmax(auto, 25vw);
  }

  .coding-page-whiteboard {
    height: 100%;
    display: grid;
    grid-template-columns: minmax(auto, 8fr) minmax(auto, 7.5vw) minmax(auto, 25vw);
  }
}

@media only screen and (max-width: 600px) {
  .sponsor2 {
    font-size: x-small;
  }

  .coding-page {
    height: 100%;
    display: grid;
    grid-template-columns: minmax(auto, 8fr) minmax(auto, 7.5vw) minmax(auto, 25vw);
  }

  .coding-page-whiteboard {
    height: 100%;
    display: grid;
    grid-template-columns: minmax(auto, 8fr) minmax(auto, 7.5vw) minmax(auto, 25vw);
  }
}

@media only screen and (max-width: 400px) {
  .coding-page {
    height: 100%;
    display: grid;
    grid-template-columns: minmax(auto, 8fr) minmax(auto, 7.5vw) minmax(auto, 25vw);
  }

  .coding-page-whiteboard {
    height: 100%;
    display: grid;
    grid-template-columns: minmax(auto, 8fr) minmax(auto, 7.5vw) minmax(auto, 25vw);
  }
}

.topMost-whiteboard {
  display: grid;
  grid-template-columns: 1fr;
}

.topMost {
  display: grid;
  grid-template-columns: 3fr 2fr;
  width: 100vw;
}

.evalSec {
  /* grid-area: eval; */
}

.videoSec {
  /* grid-area: video; */
}

.code-mirror-container {
  /* border-bottom: 2.2px solid #838486; */
  /* background-color: #282a36; */
}
Video {
  /* height: 10vh; */
}

.handleToggle * {
  border-radius: 25px;
  width: 0.15rem !important;
  transition: 0s background-color;
  transition-delay: 3s;
}

.handleToggle-dark * {
  border-radius: 25px;
  width: 0.15rem !important;
  transition: 0s background-color;
  transition-delay: 3s;
}

.handleToggle *:active,
.handleToggle *:hover {
  border-radius: 25px;
  background-color: #fcee09;
  box-shadow: 0 0 0.5px #282a36, 0 0 0 1px #282a36, 0 0 2px #282a36,
    0 0 4px #fcee09;
  transition-delay: 0s;
}

.handleToggle-dark *:active,
.handleToggle-dark *:hover {
  border-radius: 25px;
  background-color: #15b5e2;
  box-shadow: 0 0 0.5px #000, 0 0 1px #000, 0 0 2px #000, 0 0 2px #15b5e2;
  transition-delay: 0s;
}

.borderOn *{
  border-radius: 25px;
  width: 0.15rem !important;
  background-color: #fcee09;
  box-shadow: 0 0 0.5px #282a36, 0 0 0 1px #282a36, 0 0 2px #282a36,
    0 0 4px #fcee09;
  transition-delay: 0s;
}

.borderOn-dark *{
  border-radius: 25px;
  width: 0.15rem !important;
  background-color: #15b5e2;
  box-shadow: 0 0 0.5px #000, 0 0 1px #000, 0 0 2px #000, 0 0 2px #15b5e2;
  transition-delay: 0s;
}

.toolbar .clearfix {
  background: transparent;
}

.poweredBy {
  backdrop-filter: blur(1px);
  /* height: 100%; */
  max-height: 70vh;
  /* max-height: 80vh; */
  overflow-y:  auto;
}

.codex {
  margin-bottom: 2%;
}

.aiCard::-webkit-scrollbar {
  display: none;
}

.aiCard {
  height: 100%;
  /* overflow-y:  scroll; */
}

.poweredBy::-webkit-scrollbar {
  display: none;
}

.poweredBy {
  font-family: 'Source Code Pro', monospace;
}