@font-face {
  font-family: SourceCodePro;
  src: local("SourceCodePro"),
    url(../fonts/SourceCodePro-VariableFont_wght.ttf) format("truetype");
}

.dd-wrapper {
  /* border-style: dotted; */
  /* width: 25%; */
  /* transform: translateX(-100%); */
  float: left;
  z-index: 4;
  color: #fcee09;
  margin-right: 0.25rem;
  height: 35%;
  clip-path: polygon(92% 0, 100% 25%, 100% 100%, 8% 100%, 0% 75%, 0 0);
}

.dd-header-title {
  font-family: SourceCodePro;
  text-shadow:
    0 0 0.5px #fff,
    0 0 1.85px #fcee09;
  font-size: small;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .dd-wrapper {
    max-width: 3rem;
  }

  .dd-header-title {
    /* padding: 0.3rem; */
    font-size: xx-small;
  }
}

.dd-list-item {
  font-family: SourceCodePro;
  text-shadow:
    0 0 0.5px #fff,
    0 0 1.85px #fcee09;
}

.btn__label {
  font-family: SourceCodePro;
  font-size: 0.65rem;
  text-shadow:
    0 0 0.5px #fff,
    0 0 1.85px #fcee09;
  position: sticky;
  padding: 0.2rem;
  margin: 0.2rem;
  left: 80%;
  z-index: 3;
}

ul {
  list-style: none;
  padding: 0.6rem;
  margin: 0;
  font-size: x-small;
}

li:hover {
  background-color: #fcee09;
  opacity: 0.7;
  clip-path: polygon(92% 0, 100% 25%, 100% 100%, 8% 100%, 0% 0 0 75%,);
  color: white;
  animation: text-flicker 2s linear forwards;
  transition: all 0.5s ease-in;
}

@keyframes text-flicker {
  2% {
    color: #15b5e2;
    text-shadow: 0 0 15px #15b5e2;
  }
  3% {
    color: #fcee09;
    text-shadow: none;
  }
  6% {
    color: #15b5e2;
    text-shadow: 0 0 15px #15b5e2;
  }
  9% {
    color: #fcee09;
    text-shadow: none;
  }
  11% {
    color: #15b5e2;
    text-shadow: 0 0 15px #15b5e2;
  }
  14% {
    color: #fcee09;
    text-shadow: none;
  }
  18% {
    color: #15b5e2;
    text-shadow: 0 0 15px #15b5e2;
  }
  32% {
    color: #15b5e2;
    text-shadow: 0 0 15px #15b5e2;
  }
  33% {
    color: #fcee09;
    text-shadow: none;
  }
  37% {
    color: #15b5e2;
    text-shadow: 0 0 15px #15b5e2;
  }
  39% {
    color: #fcee09;
    text-shadow: none;
  }
  43% {
    color: #15b5e2;
    text-shadow: 0 0 15px #15b5e2;
  }
  46% {
    color: #fcee09;
    text-shadow: none;
  }
  47% {
    color: #15b5e2;
    text-shadow: 0 0 15px #15b5e2;
  }
  100% {
    color: #15b5e2;
    text-shadow: 0 0 15px #15b5e2;
  }
}

@media only screen and (max-width: 390px) {
  .dd-wrapper {
    float: right;
    font-size: x-small;
  }
}