.historyIcon {
    position: fixed;
    bottom: 0.9%;
    left: 0.75%;
    font-size: small;
    color: #fff;
    animation-name: none;
    -webkit-filter: drop-shadow(0 0 5px white)
    drop-shadow(0 0 1px white) drop-shadow(0 0 2px white)
    drop-shadow(0 0 3px white);

  filter: drop-shadow(0 0 .1px #15b5e2) drop-shadow(0 0 .1px #15b5e2)
    drop-shadow(0 0 1px #fff) drop-shadow(0 0 1px #fff);
    z-index: 6;
}

.historyMenu {
  z-index: 5;
  position: fixed;
  width: 100%;
  height: 30vh;
  bottom: 0%;
  backdrop-filter: blur(5px);
  /* text-align: center; */
  /* overflow-x: scroll; */
  padding: 2rem 2rem;
  /* white-space: nowrap; */
}

/* .historyMenu::-webkit-scrollbar {
  display: none;
} */


.historyCarousel {
  z-index: 5.1;
  padding-left: 1vw;
  /* outline: solid 1px red; */
  display: inline-block;
  padding: 1rem 1rem;
  vertical-align: middle;
}

.historyRender {
  z-index: 5.1;
}

@media only screen and (max-width: 390px) {
  .historyIcon {
    left: 1.7%;
  }
}