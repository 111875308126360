.login {
    position: absolute;
    left: 10%;
    top: 27vh;
    width: 17%;
}

.loginTop {
    width: 100vw;
    height: 100vh;
}

.user-box input {
    /* width: 100%; */
    padding: 10px 0;
    font-size: 12px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
    position: relative;
}

.user-box label {
    position: absolute;
    top:-20px;
    left: 0;
    padding: 10px 0;
    font-size: 12px;
    color: #fff;
    pointer-events: none;
    transition: .5s;
    font-family: Modenine-2OPd;
    text-shadow: 0 0 1px #fff, 0 0 3px #15b5e2;
}

.letsRun-login {
    font-size: .85rem;
  color: #fcee09;
  background-color: Transparent;
  border: none;
  padding: 0.5rem;
  /* width: 50px; */
  z-index: 3;
  /* position: fixed; */
  /* top: 11px;
  right: .4%; */
  background-color: rgba(255, 255, 255, .15);  
  backdrop-filter: blur(5px);
  margin: 2% 2% 0 0;
}

.loginWithGoogle {
    font-size: .85rem;
    color: #fcee09;
    background-color: Transparent;
    border: none;
    padding: 0.5rem;
    z-index: 3;
}

.letsRun-login:hover {
    animation: text-flicker 2s linear forwards;
}

.loginWithGoogle:hover {
    animation: text-flicker 2s linear forwards;
}

.letsRun-dark-login {
    font-size: .85rem;
  color: #15b5e2;
  background-color: Transparent;
  border: none;
  padding: 0.5rem;
  /* width: 50px; */
  z-index: 3;
  /* position: fixed; */
  /* top: 11px;
  right: .4%; */
  background-color: rgba(255, 255, 255, .15);  
  backdrop-filter: blur(5px);
  margin: 2% 2% 0 0;
}

.loginWithGoogle-dark {
    font-size: .85rem;
    color: #15b5e2;
    background-color: Transparent;
    padding: 0.5rem;
    border: none;
    /* width: 50px; */
    z-index: 3;
}

.GoogleLogin {
    width: fit-content;
    background-color: rgba(255, 255, 255, .15);  
    backdrop-filter: blur(5px);
    margin-top: 2%;
    padding-right: 3.5%;
}

.GoogleLogin:hover {
    animation: text-flicker 2s linear forwards;
}

.GoogleLogo{
    height: 11px;
}

.letsRun-dark-login:hover {
    animation: text-flicker 2s linear forwards;
}

.loginWithGoogle-dark:hover {
    animation: text-flicker 2s linear forwards;
}


@media only screen and (max-width: 1300px) {
    .login {
        width: 18%;
    }
}

@media only screen and (max-width: 1100px) {
    .login {
        width: 22%;
    }
}

@media only screen and (max-width: 900px) {
    .login {
        width: 26%;
    }
}

@media only screen and (max-width: 700px) {
    .login {
        width: 30%;
    }
}

@media only screen and (max-width: 500px) {
    .login {
        width: 40%;
    }
}

@media only screen and (max-width: 480px) {
    .login {
        width: 70%;
        left: 12%;
    }
	.letsRun-login {
		position: relative;
	}

	.letsRun-dark-login {
		position: relative;
	} 
}

@media only screen and (max-width: 412px) {
    .login {
        width: 70%;
        left: 12%;
    }
	/* .loginBtn {
		display: grid;
		grid-template-rows: 1fr 1fr;

	} */
	.letsRun-login {
		position: relative;
	}

	.letsRun-dark-login {
		position: relative;
	}
}

@media only screen and (max-width: 393px) {
    .login {
        width: 70%;
        left: 12%;
    }
	/* .loginBtn {
		display: grid;
		grid-template-rows: 1fr 1fr;

	} */
	.letsRun-login {
		position: relative;
	}

	.letsRun-dark-login {
		position: relative;
	}
}